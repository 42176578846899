<template>
  <div class="page">
    <div class="userInfo" v-if="mobile">
      <!-- <img :src="userInfo.avatar" alt=""> -->
      <!-- <p>昵称：{{userInfo.nickname}}</p> -->
      <p>手机号：{{ mobile }}</p>
    </div>
    <div class="kkk">
      <div class="kefuclass" @click.stop="kefu">
        联系客服获取免费试用的激活码
      </div>
    </div>

    <div class="activation">
      <div class="title">
        <img src="../../assets/jhm.png" style="width: 16px" />激活码
      </div>
      <van-field
        class="actInput"
        v-model="activationCode"
        :placeholder="appCode == 'taxlaw' ? '请输入激活码' : '请输入会员激活码'"
      ></van-field>
      <div class="ts" v-if="appCode == 'taxlaw'">
        提示：输入试用激活码后，可以免费使用7天
      </div>
      <div class="ts" v-else>
        提示：通过代理商或客服购买VIP，请输入会员激活码。
      </div>
    </div>
    <div class="button">
      <div class="sumbit" @click="sumbit">
        {{
          appCode == 'taxlaw'
            ? '使用激活码，然后进入主页面即可试用'
            : '使用激活码，然后进入主页面即可试用'
        }}
      </div>
      <div class="qrcode" v-if="appCode == 'taxlaw'">
        <!-- <p>请您点击下方获取免费试用激活码</p> -->
        <!-- <img src="../../assets/qrcode.png" style="width:150px" /> -->
        <!-- <div class="but" @click="gosbw">在线客服</div> -->
      </div>
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block1">
          <div class="dialogTop">账号绑定</div>
          <div class="dialogPhone">
            <img
              src="../../assets/icon-6.png"
              style="float: left;margin-right:10px;width:20px"
            />
            <van-field
              v-model="phone"
              placeholder="手机号"
              type="number"
            ></van-field>
          </div>
          <div class="dialogCode">
            <img
              src="../../assets/icon-7.png"
              style="float: left;margin-right:10px;width:20px"
            />
            <van-field
              v-model="code"
              placeholder="验证码"
              type="number"
            ></van-field>
            <span
              v-show="sendAuthCode"
              style="font-size: 9px;color: #378bf4;"
              @click="getCode"
              >获取验证码</span
            >
            <span v-show="!sendAuthCode" style="font-size: 9px;color: #777777;"
              >已发送({{ auth_time }})</span
            >
          </div>
          <div class="dialogSumbit" @click="dialogSumbit">确定</div>
        </div>
      </div>
    </van-overlay>
    <!-- <div
      class="backButton"
      v-if="redirectUrl"
      :style="{top:y+'px',left:x+'px'}"
      @touchmove="moveing"
      @click.stop="backButton"
    >
      <img v-if="redirectUrl" src="../../assets/returnlogo.png" @click.stop="openButton" />
    </div> -->
    <div class="meng" @click="guan" v-if="tanshow"></div>
    <div class="tan" v-if="tanshow">
      <img class="img1" src="../../assets/v1.png" alt="" />
      <img class="img1" src="../../assets/v2.png" alt="" />
    </div>
  </div>
</template>

<script>
import {
  use,
  getUserInfo,
  sendCode,
  bindMoibleByCode,
  sbwApi,
} from '@/api/member'
import { Toast } from 'vant'
export default {
  name: 'activation',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
      },
    ],
  },
  data() {
    return {
      activationCode: '',
      mobile: '',
      show: false,
      phone: '',
      code: '',
      auth_time: 0,
      sendAuthCode: true, //验证码状态
      maxX: document.documentElement.clientWidth - 50,
      maxY: document.documentElement.clientHeight - 80,
      redirectUrl: '',
      minX: 0,
      minY: 0,
      x: 100,
      y: 100,
      isOpen: false,
      appCode: '',
      sbwUrl: '',
      tanshow: false,
    }
  },
  created: function() {
    this.x = this.maxX
    this.y = this.maxY - 50
    this.appCode = this.$route.query.appCode
    this.redirectUrl = this.$route.query.redirectUrl
    this.getIsLogin()
  },
  beforeCreate() {
    if (this.$route.query.appCode == 'taxlaw') document.title = '申请试用'
  },
  mounted() {
    this.sbwApi()
  },
  methods: {
    //获取信息
    getIsLogin() {
      getUserInfo().then((res) => {
        if (res.code === 401) {
          let url = '/api/snslogin/wxgzh?redirect_uri=' + location.href
          window.location.replace(url)
          // window.location.href = "/api/snslogin/wxgzh?redirect_uri=" + url;
        }
        this.mobile = res.data.mobile
        if (this.mobile) {
          return
        } else {
          this.show = true
        }
      })
    },
    //提交激活码
    sumbit() {
      use({ key: this.activationCode }).then((res) => {
        window.console.log(res.msg)
        if (res.msg === 'success') {
          Toast('会员激活成功')
        } else {
          Toast(res.msg)
        }
        this.activationCode = ''
      })
    },
    //获取验证码
    getCode() {
      let that = this
      if (that.phone === '') {
        Toast('请输入手机号')
      } else {
        sendCode({ mobile: that.phone, codeType: 1 }).then((res) => {
          window.console.log(res)
          this.sendAuthCode = false
          this.auth_time = 60
          var auth_timetimer = setInterval(() => {
            this.auth_time--
            if (this.auth_time <= 0) {
              this.sendAuthCode = true
              clearInterval(auth_timetimer)
            }
          }, 1000)
        })
      }
    },
    //账号绑定提交
    dialogSumbit() {
      let that = this
      let obj = {
        mobile: that.phone,
        verifyCode: that.code,
        bindType: 1,
      }
      bindMoibleByCode(obj).then((res) => {
        window.console.log(res)
        Toast(res.msg)
        that.show = false
        that.getIsLogin()
      })
    },
    //返回按钮开关
    openButton() {
      if (this.isOpen) {
        this.x = this.maxX
        this.isOpen = false
      } else {
        this.x = this.maxX - 90
        this.isOpen = true
      }
    },
    //点击返回相关app
    backButton() {
      window.console.log(this.redirectUrl)
      window.location.href = this.redirectUrl
    },
    //图标拖动事件
    moveing(e) {
      e.preventDefault()
      let y = e.changedTouches[0].clientY - 31 //减去高度的一半， 使手指与图标居中
      if (this.minY < y && y < this.maxY) {
        this.y = y
      } else if (y < this.minY) {
        this.y = 0
      } else if (y > this.maxY) {
        this.y = this.maxY
      }
    },
    gosbw() {
      let data = {
        path: 'pages/index/indexsbc',
        query: '',
        version: 'release',
      }
      sbwApi(data).then((res) => {
        window.console.log(res, 'sssssss1111111111111111')
        window.location.href = res.data
      })
    },
    sbwApi() {},
    kefu() {
      this.tanshow = true
    },
    guan() {
      this.tanshow = false
    },
  },
}
</script>

<style scoped>
.userInfo {
  padding: 10px 30px;
  background-image: linear-gradient(90deg, #579cee, #297cde);
}
.userInfo p {
  margin: 10px;
  font-size: 18px;
  color: #fff;
  text-align: center;
}
.title {
  width: 70px;
  float: left;
  font-size: 16px;
  color: #222222;
  margin: 18px 0 0 0;
}
.title > img {
  vertical-align: text-top;
  margin: 0px 3px;
}
.actInput {
  width: calc(100% - 90px);
  float: left;
  padding: 1px 10px;
  margin: 10px 10px 10px 8.5px;
  border: 1px solid #c2cbd6;
  background-color: #ffffff;
  border-radius: 5px;
  color: #666666;
  font-size: 14px;
  line-height: 35px;
  height: 35px;
}
.ts {
  font-size: 13px;
  color: #999999;
  margin-left: 16px;
  margin-bottom: 10px;
}
.activation {
  padding: 5px;
  width: calc(100% - 10px);
  border-bottom: 5px solid #f0f5fa;
}
.button {
  padding: 30px 15px;
}
.sumbit {
  font-size: 18px;
  color: #ffffff;
  background-color: #fb6315;
  border-radius: 10px;
  width: 100%;
  margin: 0 0 15px 0;
  text-align: center;
  line-height: 40px;
}
.tel {
  font-size: 14px;
  color: #ffffff;
  background-color: #288aff;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  line-height: 50px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  height: 450px;
  background-color: #fff;
  border-radius: 10px;
}

.block >>> p {
  height: 370px;
  overflow-y: auto;
  text-align: justify;
}

.block1 {
  width: 80%;
  height: 250px;
  background-color: #fff;
  border-radius: 10px;
}

.offSumbit {
  margin: 5px auto;
  line-height: 30px;
  background-color: #378bf4;
  color: white;
  width: 70px;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
}

.dialogTop {
  width: 100%;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: #378bf4;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.dialogPhone {
  margin: 25px 25px 25px 25px;
  width: 80%;
  height: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f5fa;
}

.dialogPhone >>> .van-cell {
  width: 70%;
  float: left;
  padding: 0;
  font-size: 11px;
  color: #666666;
}

.dialogCode {
  margin: 25px 25px 25px 25px;
  width: 80%;
  height: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f5fa;
}

.dialogCode >>> .van-cell {
  width: 50%;
  float: left;
  padding: 0;
  font-size: 11px;
  color: #666666;
}

.dialogSumbit {
  width: 80%;
  font-size: 12px;
  color: #ffffff;
  line-height: 30px;
  background-color: #378bf4;
  margin: 0 auto;
  text-align: center;
  border-radius: 20px;
}

.van-cell:not(:last-child)::after {
  border-bottom: 0;
}
.backButton {
  position: fixed;
  width: 150px;
  height: 62px;
  background: url('../../assets/return.png');
  background-size: contain;
  z-index: 999;
  transition: left 1.5s;
}
.backButton > img {
  position: relative;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
}
.qrcode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #999999;
}
.qrcode > p {
  margin-top: 30px;
  margin-bottom: 32px;
}
.but {
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #288aff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
}
.kkk {
  width: 100%;
  display: flex;
  justify-content: center;
}
.kefuclass {
  background: #288aff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  text-align: center;
}
.tan {
  width: 88%;
  position: fixed;
  top: 11%;
  left: 6%;
  background: #fff;
  z-index: 2;
  border-radius: 10px;
}
.img1 {
  width: 100%;
  height: 155px;
  border-radius: 10px;
  margin: 10px 0;
}
.meng {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}
</style>
